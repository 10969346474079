import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CallToAction from "../components/calltoaction"
import BlogPreview from "../components/blog/blogpreview"
import CaseStudy from "../components/homepage/casestudy"
import Hero from "../components/homepage/hero"
import Process from "../components/homepage/process"
import Quotes from "../components/homepage/quotes"
import Stats from "../components/homepage/stats"
import ServicesOverview from "../components/homepage/servicesoverview"
import Story from "../components/homepage/story"

const IndexPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <Hero />
      
      <div className="lightblue-bg">
        <Stats />
        <Quotes />
      </div>
      
      <Process />
      
      <div className="lightblue-bg">
        <ServicesOverview />
      </div>
      
      {/* <CaseStudy /> */}
      
      <Story />
      
      <div className="lightblue-bg">
        <CallToAction baseName="cta-1" hasWrapper={true} />
      </div>
      
      <BlogPreview />
      
    </Layout>
  )
}

export default IndexPage

const seo = {
  metaDesc: "PlexTech Marketing - Full Lifecycle Marketing for mid sized and growing businesses",
}

export const Head = () => <Seo title="PlexTech Marketing" type="website" url="/" seo={seo} />
