import * as React from "react"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import dayjs from "dayjs"

const BlogPreview = () => {
  const { allBlogpostsJson } = useStaticQuery(graphql`
    query IndexQuery {
      allBlogpostsJson(sort: { postDate: DESC }, limit: 3) {
        nodes {
          title,
          slug,
          content,
          postDate,
          image {
            name,
            src {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)
  
  return (
    <div className="container">
      <div className="row">
        {allBlogpostsJson.nodes.map((post: any) => (
          <div className="col-md-4" key={post.slug}>
            <div className="fh5co-property">
              <figure>
                <Link to={`/${post.slug}`}>
                  <GatsbyImage image={post.image.src.childImageSharp.gatsbyImageData} alt={post.image.name} objectFit="cover" />
                </Link>
                <span className="tag">{dayjs(post.postDate).format("MMM D YYYY")}</span>
              </figure>
              <div className="fh5co-property-innter">
                <h3><Link to={`/${post.slug}`}>{post.title}</Link></h3>
                <p>{`${post.content.replace(/\n\n/g, " ").substring(0, 100).trim()} `}<Link to={`/${post.slug}`}>&#8230; More &gt;</Link></p>
              </div>
              
              <p className="fh5co-property-specification">
                <span><strong>By</strong> PlexTech Marketing</span>
              </p>
                
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogPreview
