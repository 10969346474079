import React, { useEffect, useState } from "react"

const Quotes = () => {
  return (
    <div className="container with-bottom-padding">
      <div className="row">
        <div className="col-md-6 with-right-border">
          <div className="paper">
            <div className="pin">
              <div className="shadow"></div>
              <div className="metal"></div>
              <div className="bottom-circle"></div>
            </div>
            <p><i>We started the company because we were tired of marketing promises. Marketing should be driven by human interaction while being results oriented.</i></p>
          </div>
          
        </div>
        
        <div className="col-md-6">
          <p>&quot;Rob &amp; Harry took the time to explain the entire process and didn&apos;t use smoke and mirrors. There were a lot of things we didn&apos;t understand but the transparency and trusting their process paid off measurably. We saw viable results and couldn&apos;t be happier.&quot; - Larry W. (Hair by Larry)</p>
        </div>
      </div>
    </div>
  )
}

export default Quotes
