import React, { useEffect, useState } from "react"
import { FaArrowRight, FaCheck } from 'react-icons/fa'

interface StatCardProps {
  icon: any;
  title: string;
  index: number;
  children?: React.ReactNode;
}

const ProcessItem: React.FC<ProcessItemProps> = ({ icon, title, index, children }) => {
  return (
    <div className={`rb_roadmap_item col-md-3 col-sm-6 ${index === 0 ? 'active' : ''}`}>
      <div className="roadmap_icon_wrapper">
        {icon}<span className="number title_ff">
          {index === 0 ?
            <FaCheck /> :
            index}
        </span>
      </div>
      <span className="roadmap_divider">
        {index !== 0 && <FaArrowRight />}
      </span>
      <div className="roadmap_content_wrapper">
        <p className="h3 roadmap_title">{title}</p>
        <p className="roadmap_description">
          {children}
        </p>
      </div>
    </div>
  )
}

export default ProcessItem
