import React, { useEffect, useState } from "react"
import { FaBuilding, FaHandshake, FaSearch, FaShoppingCart } from 'react-icons/fa'
import { PiBuildingsFill } from 'react-icons/pi';

import StatCard from "./statcard"

const Stats = () => {
  return (
    <div className="container stats-panel">
      <div className="row">
        <StatCard
          icon={<PiBuildingsFill />}
          title="35%"
        >
          of B2B marketing executives say they can calculate the ROI of their marketing spend most or all of the time.
        </StatCard>
        
        <StatCard
          icon={<FaHandshake />}
          title="79%"
        >
          of marketing leads don’t ever make the conversion into a sale, commonly caused by a lack of lead nurturing.
        </StatCard>
        
        <StatCard
          icon={<FaSearch />}
          title="48%"
        >
          of searches result in an organic click. The remaining 52% click on a paid ad or leave.
        </StatCard>
        
        <StatCard
          icon={<FaShoppingCart />}
          title="50%"
        >
          of qualified leads are not yet ready to buy.
        </StatCard>
      </div>
    </div>
  );
}

export default Stats
