import React, { useEffect, useState } from "react"

interface StatCardProps {
  icon: any;
  title: string;
  children?: React.ReactNode;
}

const StatCard: React.FC<StatCardProps> = ({ icon, title, children }) => {
  return (
    <div className="col-md-3">
      <div className="statcard">
        <div className="statcard-icon statcard-blue">
          {icon}
        </div>
        
        <div className="statcard-inner">
          <h3>{title}</h3>
          
          {children}
        </div>
      </div>
    </div>
  )
}

export default StatCard
