import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FaCheck } from 'react-icons/fa'

import Funnel from "../forms/funnel";

const typeList = [
  "No commitment consultation session. Just select your requirement.",
  "Find out how we help walk your customers through each step of the sales process.",
  "No need to hire different people to configure dozens of new apps. We do it.",
];

const Hero = () => {
  const [curItem, setCurItem] = useState(0);
  const [curType, setCurType] = useState("");
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurItem((selItem) => {
        setCurType((existingText) => {
          if(existingText !== undefined) {
            if(existingText.length < typeList[selItem].length) {
              return typeList[selItem].substring(0, existingText.length + 1);
            }
            else {
              return existingText;
            }
          }
          else {
            return "";
          }
        })
        
        return selItem;
      })
    }, 30);
    
    const interval2 = setInterval(() => {
      setCurItem((selItem) => {
        setCurType("")
        
        if(selItem + 1 < typeList.length) {
          return selItem + 1;
        }
        else {
          return 0;
        }
      })
    }, 10000);
    
    return () => {
      clearInterval(interval);
      clearInterval(interval2)
    };
  }, []);
  
  return (
    <div id="fh5co-hero" clsas="js-fullheight">
      <div className="flexslider js-fullheight">
        <ul className="slides">
          <li className="hero1" style={{ display: "block" }}>
            <div className="container">
              <div className="col-md-12 text-center js-fullheight fh5co-property-brief slider-text animated fadeInUp">
                <div className="hero-inner">
                  <div className="tagline-container">
                    <div className="tagline-cell">
                      <div className="tagline">
                        <h3>Are you anxious that customers are clicking off of your site?</h3>
                        
                        <div className="header-type">
                          <div className="text">{curType}<div className="cursor"></div></div>
                        </div>
                        
                        <div className="header-bullet"><div className="tick"><FaCheck /></div>Staticistics driven. No “secrets”.</div>
                        <div className="header-bullet"><div className="tick"><FaCheck /></div>Transparent reporting we share with you.</div>
                        <div className="header-bullet"><div className="tick"><FaCheck /></div>We install and configure required services.</div>
                        <div className="header-bullet"><div className="tick"><FaCheck /></div>In-house development team. Fast customization.</div>
                      </div>
                    </div>
                    
                    <div className="header-form">
                      
                      <div className="header-form-title">
                        <h3>No Commitment Consultation</h3>
                      </div>
                      <div className="header-form-container">
                        
                        <Funnel baseName="hero" />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </li>
          
        </ul>
      </div>
    </div>
  );
}

export default Hero
