import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight } from "react-icons/fa"

import ContactUsForm from "../forms/contactus"

const Story = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  
  return (
    <div className="white-section skyline-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>Our Story</h2>
            
            <p>Rob has decades of experience in advanced IT and making highly targeted marketing campaigns across numerous industries including solar & health care. Harry worked as a web developer on projects ranging from business websites to customer management applications.</p>
            
            <p>It quickly became clear that one of the big headaches was coordination across multiple disciplines. Both had extensive experience of outsourced work to remote companies and the issues that entailed. It seemed clear that combining skillsets could save time not just for the company but for clients too.</p>
            
            <button className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Talk to Us Now <FaArrowRight /></button>
          </div>
        </div>
      </div>
      
      <ContactUsForm baseName="story" isOpen={isContactFormOpen} fnClose={() => setIsContactFormOpen(false)} />
    </div>
  );
}

export default Story
