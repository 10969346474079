import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaChartLine, FaEdit, FaExchangeAlt, FaSearch, FaPhoneAlt } from 'react-icons/fa'

import ProcessItem from "./processitem"

const Process = () => {
  return (
    <div className="process-panel">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>Our Process</h2>
          </div>
        </div>
        <div className="row process-steps-row">
          
          <ProcessItem
            icon={<FaEdit />}
            title="Plan Campaign"
            index={1}
          >
            Discover current strengths and weaknesses
          </ProcessItem>
          
          <ProcessItem
            icon={<FaChartLine />}
            title="Collect Statistics"
            index={2}
          >
            Install industry specific customer data collection services
          </ProcessItem>
          
          <ProcessItem
            icon={<FaExchangeAlt />}
            title="Tailor Campaign"
            index={3}
          >
            Make multiple versions to test which performs better in the real world
          </ProcessItem>
          
          <ProcessItem
            icon={<FaSearch />}
            title="Monitor Results"
            index={0}
          >
            Assess best performing pages and continue to improve
          </ProcessItem>
        </div>
        
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <hr />
            <p>We build a plan specific to your product or service. What traffic streams you already have and which new ones would work well.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process
